@import "../../styles/responsive.scss";
#contact {
  .back-img {
    background-image: url("../../assets/roofing-tile.jpg");
    min-height: 400px;
    background-position: center;
    background-size: cover;
    position: relative;

    .opacity {
      background-color: #14261c;
      opacity: 0.65;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    span {
      line-height: 1.3em;
      color: #fff;
      font-size: 3.4rem;
      font-weight: 700;
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translate(50%, -50%);
      z-index: 2;
    }
  }
  .sec-2 {
    display: flex;
    justify-content: space-between;
    margin: 60px 0;
    @include small {
      flex-direction: column;
      align-items: center;
    }
    iframe {
      width: 600px;
      height: 450px;
      border: "border:0";

      @include small {
        display: none;
      }
    }
    .sMap {
    }
  }
  .sec-3 {
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;

    @include small {
      flex-direction: column;
      align-items: center;
    }
    #contactCard {
      box-shadow: 0px 0px 20px 0px rgba(205.5, 205.5, 205.5, 0.5);
      margin: 10px;
      transition: background 0.3s, border 0.3s, border-radius 0.3s,
        box-shadow 0.3s;
      display: flex;
      padding: 20px;
      max-width: 250px;
      flex-direction: column;
      text-align: center;
      flex: 1;

      .line {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        &::before {
          content: "";
          border: 1px solid #8d4a2e;
          width: 10px;
          display: flex;
          align-items: center;
          margin-right: 5px;
        }
      }

      img {
        max-width: 30px;
        max-height: 30px;
        border-radius: 50%;
        border: 3px solid #8d4a2e;
        padding: 10px;
        width: 100%;
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .icon {
        margin-bottom: 20px;
      }
      h6 {
        color: #561e14;
        font-weight: bold;
        font-size: 23px;
      }
      h3 {
        color: #000000;
        font-weight: bold;
        margin-bottom: 20px;
      }
      span {
        color: #000000;
        font-weight: 400;
      }
    }
  }
}
