@import "../../../../styles/responsive.scss";

#header {
  .top-header {
    .top-header-wrapper {
      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f5a678;
        min-height: 50px;
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        z-index: 3;

        @include small {
          text-align: center;
        }

        .social {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            li {
              margin-top: 0;

              &:first-of-type {
                margin-top: 0;
              }

              &:last-of-type {
                margin-right: 0px;
              }

              img {
                width: 28px;
                height: 28px;
                margin-right: 10px;
                opacity: 0.5;

                &:hover {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;

    @include small {
      flex-direction: column;
    }

    .logo {
      max-width: 180px;
      flex: 1;
    }
    h2 {
      color: #5b341d;
      font-size: 70px;
      font-weight: 800;
      line-height: 84px;
      text-align: center;

      @include small {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 30px;
      }
    }

    .header-right {
      @include small {
        margin-bottom: 30px;
        margin-top: 20px;
      }
      .header-contact {
        display: flex;
        justify-content: space-between;

        @include small {
          flex-direction: column;
          align-items: center;
          padding: 0 !important;
        }

        .contact-info {
          display: flex;
          align-items: center;
          text-align: center;
          .icon {
            max-width: 19px;
          }
          li {
            display: flex;
            align-items: baseline;
            margin-right: 20px;

            &:last-of-type {
              margin-right: 50;
            }

            a {
              font-weight: bold;
              font-size: 14px;
              line-height: 19px;
            }

            .phone-numbers {
              display: flex;
              flex-direction: column;
            }

            img {
              margin-right: 15px;
            }
            .linkdedin {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
