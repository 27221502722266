@mixin large {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin mid {
  @media (max-width: 1400px) {
    @content;
  }
}
@mixin small {
  @media (max-width: 940px) {
    @content;
  }
}
