@import "../../styles/responsive.scss";
#home {
  .sec-1 {
    display: flex;
    justify-content: space-between;
    min-height: 500px;
    position: relative;
    padding: 50px 0;
    background-image: url("../../assets/background-sec-1.jpg");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    transition: 0.3s, border-radius 0.3s, opacity 0.3s;

    @include small {
      flex-direction: column;
      text-align: center;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .text {
        margin-bottom: 70px;
        z-index: 2;

        @include small {
          align-items: center;
          display: flex;
          flex-direction: column;
        }
        h3 {
          font-size: 50px;
          line-height: 1.3em;
          font-weight: bold;
          color: #fff;
          max-width: 840px;
          text-transform: capitalize;

          @include small {
            font-size: 40px;
          }
        }

        p {
          font-size: 15px;
          color: #fff;
          line-height: 20px;
          margin-top: 20px;
          max-width: 660px;
        }
      }
    }
  }

  .opacity {
    opacity: 0.61;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #4e5652;
    background-color: #4e5652;
    width: 100%;
    height: 100%;
  }
  .sec-2 {
    display: flex;
    padding: 50px 0;

    @include small {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    img {
      @include small {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    .content {
      margin-right: 20px;
      h3,
      h2,
      p {
        color: rgb(34, 34, 34);
        font-size: 37px;
        font-weight: 400;
        text-decoration: none solid rgb(34, 34, 34);
        font-weight: bold;
        margin-bottom: 20px;
      }
      h2 {
        font-size: 30px;
        font-weight: normal;
        margin: 10px 0;
      }
      p {
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 40px;
        line-height: 18px;
      }
    }
  }
  .sec-3 {
    background-color: #683b28;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid #b48e79;
    padding: 15px;
    text-align: center;
    line-height: 20px;
    position: relative;
    margin-top: 150px;

    @include small {
      margin-top: 60px;
    }

    h3 {
      color: #fff;
      font-size: 28px;
      font-weight: 500;
      font-style: normal;
      text-decoration: none solid rgb(34, 34, 34);
      margin-top: 120px;

      @include small {
        margin-top: 60px;
      }
    }

    span {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      text-decoration: none solid rgb(34, 34, 34);
      max-width: 930px;
    }

    img {
      margin: 30px 0;
    }
    .card-wrapper {
      display: flex;
      gap: 20px;
      margin: 50px 0;

      @include small {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .exp-wrapper {
      display: flex;
      position: absolute;
      top: -18%;

      @include small {
        top: -4%;
      }
      span {
        padding: 50px;
        color: #eda081;
        flex: 1;
        font-size: 1.7rem;
        line-height: 1.3em;
        font-weight: bold;
        background-color: #492117;
        @include small {
          font-size: 1em;
          padding: 10px;
          line-height: 1em;
        }
        &:nth-child(1) {
        }
        &:nth-child(2) {
          border-left: 1px solid #492117;
          border-right: 1px solid #492117;
          background-color: #eda081;
          color: #492117;
        }
        &:nth-child(3) {
        }
      }
    }
  }
}
