#footer {
  background-color: #683b28;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;

  span {
    padding: 10px 0;
    color: #fff;
    margin-top: 30px;
  }

  .patner {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 20px;
    flex-wrap: wrap;

    .parnter-text {
      font-size: 25px;
      font-weight: bold;
      margin-right: 20px;
    }
    .wrapper {
      ul {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        li {
          flex: 1;
          img {
            max-height: 50px;
            object-fit: contain;
          }
        }
      }
    }
  }
}
