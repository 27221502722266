.wrapper-button {
  z-index: 2;
  #button {
    background-color: #95563c;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    padding: 15px;
    font-weight: bold;

    &:hover {
      background-color: #95573ccc;
    }

    span {
      text-transform: uppercase;
      color: #fff;
    }
  }
}
