@import "./responsive.scss";

body,
html {
  font-family: "Noto Sans Georgian", sans-serif;
}
span,
a,
button,
h2,
p,
div {
  font-family: "Noto Sans Georgian", sans-serif;
  color: #612e12;
}

.container {
  padding-right: 300px !important;
  padding-left: 300px !important;

  @include large {
    padding-right: 150px !important;
    padding-left: 150px !important;
  }

  @include mid {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  @include small {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}

main {
  flex: 1;
}

// .image-gallery-content .image-gallery-slide .image-gallery-image {
//   width: 1000px;
//   height: 500px;
// }
// .image-gallery-thumbnail .image-gallery-thumbnail-image {
//   width: 90px;
//   height: 54px;
// }

.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  @include small {
    max-width: 370px;
  }
}
