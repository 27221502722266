#card {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  position: relative;
  width: 230px;
  height: 230px;
  object-fit: cover;
  object-position: 50% 50%;
  cursor: pointer;
  background-size: contain;
}

.card-tittle {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  color: #fff;
}

.more {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  color: #fff;
  background-color: #a55f41;
  width: 100%;
  padding: 15px 0;

  &:hover {
    background-color: rgba(149, 87, 60, 0.8);
  }
}

.oppacity {
  opacity: 0.61;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #4e5652;
  width: 100%;
  height: 100%;
}
